import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import PageHeader from "../_pageHeader";
import AutoScroll from "../_autoScroll";

const Contact = (props) => {


  return (
    <>
      {props.header &&
          <>
          <PageHeader/>
          <AutoScroll/>
          </>
      }
      <div id="contactStrate1" className={"strate bottom"}>
        <h1 className={"picture"}>Une question ? contactez-nous !</h1>

        <div id={"contact_item_wrapper"}>
          <div className={"contact_item"} id={"contact_item_phone"}>
            <div className={"content"}>
              <div className={"title"}>Par téléphone</div>
              <div className={"contact_link"}><a href={"tel:0186476123"}>01 86 47 61 23</a></div>
              <div className={"detail"}>
                (Appel non surtaxé, prix d’un appel local, <br className={"d-lg-none"}/>
                tarif en vigueur selon opérateur. <br/>
                Ligne active du lundi au vendredi, de <br className={"d-lg-none"}/>
                9h30 à 17h30, hors jours fériés)
              </div>
            </div>
          </div>

          <div className={"separator"}/>

          <div className={"contact_item"} id={"contact_item_mail"}>
            <div className={"content"}>
              <div className={"title"}>Par e-mail</div>
              <div className={"contact_link"}><a href={'mailto:choisis-ton-match-tictac@tlcrewards.com?subject=Op%C3%A9ration%20%22Choisis%20ton%20match%20Tic%20Tac%C2%AE%22%20%2F%20Vos%20nom%20et%20pr%C3%A9nom'}>choisis-ton-match-<br className={"d-lg-none"}/>tictac@tlcrewards.com</a>
              </div>
              <div className={"detail"}>
                (Objet : Opération “Choisis ton match <br className={"d-lg-none"}/>
                Tic Tac” / Vos nom et prénom)
              </div>
            </div>
          </div>

        </div>

        {props.mention &&
            <div className={"contact_mention"}>
              Retrouvez toute notre actualité sur<br/>
              <a href={"https://www.tictac.com/fr/fr"} target={"_blank"}>www.tictac.com/fr/fr</a>
            </div>
        }

      </div>
    </>
  );
};

Contact.defaultProps = {
  header : true,
  mention : true
}

export default Contact;

import React, {useEffect} from "react";

const AutoScroll = () => {

    useEffect(() => {
       if(window.innerWidth >= 1024 ){
           setTimeout(() => {
               window.scrollTo({
                   top: 700,
                   behavior: 'smooth',
               });
           },1000);
       }else{
           setTimeout(() => {
               window.scrollTo({
                   top: window.innerWidth * 1.66,
                   behavior: 'smooth',
               });
           },1000);
       }

    });

    return(<></>)
}

export default AutoScroll
import React from 'react';

const CustomComboBox = ({label, name, id=name, options = [], placeholder="", register, error, className=""} ) =>{

  label = label.replace("*","<span>*</span>");

  return(
    <div className={"form_row_wrapper"} id={"form_row_wrapper_"+id}>

      <div className={"label_wrapper"}>
        <label dangerouslySetInnerHTML={{__html: label}}/>
      </div>

      <div className={"input_wrapper"}>
        <select name={name} id={id} defaultValue="" className={className} ref={register} required={true}>
          {placeholder !== "" && <option disabled={true} value="">{placeholder}</option>}
          {options.map( (option, key) => <option key={key} value={option[1]}>{option[0]}</option>)}
        </select>

        <div className="error" dangerouslySetInnerHTML={{__html : error}}/>
      </div>

    </div>
  );
}

export default CustomComboBox;
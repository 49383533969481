import React from 'react';

import Contact from "./contact";
import PageHeader from "../_pageHeader";

const FinOpe = () => {

	return(
		<>
			<PageHeader/>

			<div id="finOpeStrate1" className={"strate"}>
				<div className={"mention"}>
					Quelle que soit votre team,<br className={"d-lg-none"}/> vous faites tous partie <br className={"d-none d-lg-block"}/>de la grande <br className={"d-lg-none"}/>famille Tic Tac® !
				</div>
			</div>

			<Contact header={false}/>
		</>
	);
}
export default FinOpe;

import React from 'react';
import Contact from "./contact";
import PageHeader from "../_pageHeader";
import AutoScroll from "../_autoScroll";

const MentionsLegales = () => {
  return (
    <>
      <AutoScroll/>
      <PageHeader/>

      <div className={"strate"} id={"mlStrate1"}>

        <h1 className={"picture"}>Informations légales</h1>

        <div id={"ml_wrapper"}>
          <div className="block">
            <h4>Ce site est créé par :</h4>
            <p>
              <span>TLC Marketing</span><br/>
              92 avenue de Wagram<br/>
              75017 Paris<br/>
              <br/>
              <span>N° SIREN :</span> 491 414 306<br/>
              <span>N° SIRET :</span> 491 414 306 00036<br/>
              <span>APE :</span> 7311Z<br/>
              <span>RCS :</span> Paris B 491 414 306<br/>
              SARL au capital social de : 150 000€<br/>
              <span>Numéro de TVA intracommunautaire</span> :<br/> FR 70 491 414 306<br/>
              Adresse de courrier électronique : <br/>
              <a href="mailto:bonjour@tlcmarketing.com">bonjour@tlcmarketing.com</a>
            </p>
          </div>
          <div className="block">
            <h4>Pour le compte de :</h4>
            <p>
              <span>FERRERO France</span><br/>
              18 rue Jacques Monod<br/>
              76130 Mont-Saint-Aignan<br/>
              <br/>
              <span>N° SIREN :</span> 602 018 897<br/>
              <span>N° SIRET :</span> 602 018 897 00168<br/>
              <span>RCS :</span> Rouen B 602 018 897<br/>
              SAS au capital social de : 152 400€<br/>
              <span>Numéro de TVA intracommunautaire :</span> FR 26 602 018 897
            </p>
          </div>
          <div className="block">
            <h4>Les pages du site dédiées à cette <br className={"d-none d-lg-block"}/>opération sont hébergées par la société :</h4>
            <p>
              Cloud Media<br/>
              6 rue du Général Clergerie<br/>
              75116 PARIS<br/><br/>

              N° SIREN : 752 470 971<br/>
              N° SIRET : 752 470 971 00025<br/>
              RCS : Paris B 752 470 971<br/>
              SAS au capital social de : 8 000€<br/>
              Numéro de TVA intracommunautaire :<br/>
              FR 19 752 470 971
            </p>
          </div>

          <div className={"block"}>
            <p>
              La conception, le design et le <br className={"d-lg-none"}/>
              développement du site ont été effectués <br className={"d-lg-none"}/>
              par les agences suivantes : <br className={"d-none d-lg-block"}/> TLC Marketing <br className={"d-lg-none"}/>
              et Cloud Media.
            </p>
          </div>

        </div>
      </div>


    </>

  );
};

export default MentionsLegales;



import React, {useState} from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import { Modal, Form } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import ReactGA from "react-ga";

class CookieBanner extends React.Component {

	constructor(props){
		super(props)

		this.state = { show_modal : false}
		this.cookies = new Cookies();

		this.cookie_accept_all = this.cookie_accept_all.bind(this)
		this.cookie_reject_all = this.cookie_reject_all.bind(this)
		this.cookie_personalize = this.cookie_personalize.bind(this)
		this.cookie_set_cookie = this.cookie_set_cookie.bind(this)

		//affichage ou non du bandeau plus chargement des preferences
		/*this.show_cookie_banner = this.cookies.get('pn_cookie_accept') === undefined ? true : !this.cookies.get('pn_cookie_accept')
		this.cookie_pref = this.cookies.get('pn_cookie_pref')
		
		if(this.cookie_pref !== undefined)*/
		this.cookie_set_cookie(true)
	}

	//affichage de la banière
	cookie_show_banner(){
		document.getElementById("cookie_banner").classList.remove("hide")
	}

	cookie_hide_banner(){
		document.getElementById("cookie_banner").classList.add("hide")
	}

	//accepter tout les cookies
	cookie_accept_all(){
		this.cookie_hide_banner()

		//activation des cookies
		this.cookie_set_cookie(true);

		//set du cookie d'affichage du bandeau
		this.cookies.set('pn_cookie_accept', true, { path: '/' });
	}

	//refuser tout les cookies
	cookie_reject_all(){
		this.cookie_hide_banner()

		//activation des cookies
		this.cookie_set_cookie(false);

		//set du cookie d'affichage du bandeau
		this.cookies.set('pn_cookie_accept', true, { path: '/' });

		//mise a false des cookies
		this.cookies.set('pn_cookie_pref', { "analytics" : false } , { path: '/' });
	}

	//validation des preferences
	cookie_personalize(){
		let analytics = document.getElementById("cookie_analytics").checked;

		this.cookie_set_cookie(analytics);

		//save des preferences dans un cookie
		this.cookies.set('pn_cookie_pref', { "analytics" : analytics } , { path: '/' });
		this.cookies.set('pn_cookie_accept', true, { path: '/' });

		this.cookie_hide_banner()
		this.setState({ show_modal : false })
	}

	//activation/desactivation des cookies
	cookie_set_cookie(analytics){

		if(analytics === true){

			//console.log(process.env.REACT_APP_GA);

			ReactGA.initialize(
				[ { trackingId : process.env.REACT_APP_GA } ],
				{ debug: true }
			);
		}

	}

	render(){

		let cookie_banner_class

		if(this.show_cookie_banner)
			cookie_banner_class = ""
		else
			cookie_banner_class = "hide"

		return (
			<>
				<Modal centered show={this.state.show_modal} onHide={() => ( this.setState({show_modal : false}) )} id={"cookie_modal"}>
					<Modal.Header>
						<Modal.Title>Personnaliser vos cookies</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Check id="cookie_analytics" defaultChecked={false} type="checkbox" label="Analytics : Ces cookies nous permettent de déterminer le nombre de visites et les sources de trafic sur notre site, afin de mesurer et d'améliorer leurs performances, ils nous aident également à identifier les pages les plus / moins visitées et à évaluer la manière dont les visiteurs naviguent sur le site. Toutes les informations collectées par ces cookies sont agrégées et donc anonymes. Si vous n'acceptez pas cette catégorie de cookies, nous ne saurons pas quand vous avez visité notre site Web." />
					</Modal.Body>
					<Modal.Footer>
						<button className="mx-auto cta blue" onClick={this.cookie_personalize}>Valider</button>
					</Modal.Footer>
				</Modal>

				<div id="cookie_banner" className={"hide"}>
					<p>Nous procédons à l’utilisation de cookies dans un but d’analyser la performance du site. <a href="/file/Politique%20de%20cookies.pdf" target={"_blank"}>Politique de cookies</a>.</p>
					<div>
						<button id="cookie_accept_all" className="cta blue" onClick={this.cookie_accept_all} >Tout accepter</button>
						<button id="cookie_customize" className="cta alternative_blue" onClick={() => this.setState({ show_modal : true })} >Personnaliser</button>
					</div>
				</div>
			</>
		)
	}
}

export default CookieBanner;

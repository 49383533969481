import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Layout from './component/_Layout';
import history from './component/services/history';

function App() {

  return (
    <Router history={history} basename={"/fr/fr/xp/choisistonmatch"}>
      <Layout/>
    </Router>
  );
}

export default App;
